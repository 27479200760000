body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Acens';
  src: url(./fonts/Acens.ttf);
}

@font-face {
  font-family: 'Concord';
  src: url(./fonts/ConcordThin.ttf);
}

@font-face {
  font-family: 'ConcordLight';
  src: url(./fonts/ConcordLight.ttf);
}

@font-face {
  font-family: 'ConcordBold';
  src: url(./fonts/ConcordBold.ttf);
}

h1 {
  font-family: 'ConcordBold';
}

a, p, span {
  font-family: 'ConcordLight';
}
