.Services {
    text-align: center;
    color: #424343;
    padding: 80px 0 0;
}

.Services h1 {
    font-size: 60px;
    margin: 0;
    padding: 0;
    letter-spacing: 2px;
}

.Services h2 {
    font-weight: 400;
    text-transform: uppercase;
    font-family: 'ConcordLight';
    margin: 0;
    padding: 30px 0 50px;
    font-size: 32px;
}

.Services .services-home {
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin-top: -80px;
    padding-top: 120px;
    background-color: #f1f1f1;
    padding-bottom: 50px;
}

.Services .services-home .box {
    display: flex;
    flex-wrap: wrap;
    width: 200px;
    justify-content: center;
}

.Services .services-home .box img {
    width: 200px;
}

.Services .services-home .box a {
    padding: 10px 24px;
    color: white;
    background: #2b9f8d;
    text-decoration: none;
    border-radius: 4px;
    margin: 20px 0;
}

@media only screen and (max-width: 1000px) {
    .Services .services-home {
        flex-wrap: wrap;
    }

    .Services h1 {
        font-size: 40px;
    }
    
    .Services h2 {
        font-size: 28px;
    }
}