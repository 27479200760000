.Top {
    background: url(../../../../imgs/fundotop.jpg);
    background-size: cover;
    background-position: bottom;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.Top .services-home {
    display: flex;
    width: 100%;
    justify-content: space-around;
    padding: 7% 0 19% 0;
}

.Top .services-home .box {
    display: flex;
    flex-wrap: wrap;
    width: 30%;
    justify-content: center;
}

.Top .services-home .box img {
    width: 90%;
}

@media only screen and (max-width: 600px) {
    .Top {
        background: url(../../../../imgs/fundotopmin.jpg);
    }
    .Top .services-home .box {
        width: 40%;
    }
}

@media only screen and (min-width: 1600px) {
    .Top .services-home {
        padding: 3% 0 9% 0;
    }
    .Top .services-home .box img {
        width: 60%;
    }
}