.Video {
    border-top: #1b7364 14px solid;
    background-color: #000;
    color: #fff;
    padding: 0 0 8% 0;
}

.Video h1 {
    font-size: 60px;
    margin: 0;
    padding: 2% 0 2% 0;
    letter-spacing: 2px;
    text-align: center;
}

.video-responsive {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 60vh;
}

.play-icon {
    text-align:center;
}

.bt-watch {
    font-size: 32px!important;
    color: white;
    text-decoration: none;
    background-color: transparent;
    padding: 70px 24px 0 24px!important;
    font-family: 'Rubik', sans-serif;
    font-weight: bold;
    border: none;
    border-radius: 6px;
}

.background-video .icon {
    background: none;
    border: none;
}
@media only screen and (max-width: 600px) {
    .Video {
        padding: 0 0 60px 0;
    }
    .video-responsive {
        width: 100%;
        height: 50vh;
    }
}

@media only screen and (max-width: 1000px) {
    .Video h1 {
        font-size: 36px;
    }
    .background-video {
        height: auto;
        padding: 30px 0;
    }
    .bt-watch {
        font-size: 16px;
        margin-top: 15px;
    }
}