.Profile {
    background: url(../../../../imgs/fundoprofile.png);
    background-size: 80% auto;
    background-position: top;
    background-repeat: repeat-x;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.Profile .container {
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #2b9f8d;
    text-align: center;
}

.Profile .container .box {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.Profile .container .box img {
    margin: -8% 0 6% 0;
    width: 30%;
}

.Profile .container h1 {
    text-transform: uppercase;
    font-family: 'ConcordLight';
    font-size: 55px;
    line-height: 50px;
    margin: 0;
    padding: 0 0 10px 0;
}

.Profile .container h1 span {
    font-family: 'ConcordBold';
}

.Profile .container h4 {
    font-family: 'ConcordBold';
    font-size: 28px;
    margin: 0;
    padding: 0;
    padding: 0 0 30px 0;
}

.Profile .social {
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    padding: 0 0 50px 0;
}

.Profile .social a {
    color: #424343;
    margin: 5px;
    padding: 10px;
    border: 4px solid #424343;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease-in-out;
}

.Profile .social a:hover {
    color: #6e6e6e;
    border: 4px solid #6e6e6e;
}

.Profile .social a span {
    text-align: center;
    font-size: 28px;
    width: 40px!important;
    height: 40px!important;
}

@media only screen and (max-width: 600px) {
    .Profile .container .box img {
        margin: -12% 0 6% 0;
        width: 40%;
    }
}

@media only screen and (min-width: 1600px) {
    .Profile {
        background: url(../../../../imgs/fundoprofile.png);
        background-size: 45% auto;
        background-position: top;
        background-repeat: repeat-x;
    }
    .Profile .container .box img {
        margin: -6% 0 2% 0;
        width: 20%;
    }
}
