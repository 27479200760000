.ContactForm {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 80px 0 0;
}

.ContactForm form {
    width: 600px;
    color: #404040;
}

.ContactForm form h3 {
    font-family: 'ConcordBold';
    font-size: 40px;
    margin: 0;
    margin-bottom: 30px;
}

.ContactForm form input {
    font-family: 'ConcordBold';
    width: 100%;
    font-size: 16px;
    border: 1px solid #666666;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 20px;
}

.ContactForm form textarea {
    font-size: 16px;
    font-family: 'ConcordBold';
    width: 100%;
    border: 1px solid #666666;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 20px;
}

.ContactForm form .submitButton {
    background-color: #2b9f8d;
    border: none;
    font-family: 'ConcordBold';
    color: white;
    width: 180px;
}

.ContactForm .contactinfo {
    background-color: #f1f1f1;
    width: 100%;
    display: flex;
    margin-top: 100px;
    justify-content: space-between;
    align-items: center;
}

.ContactForm .contactinfo img {
    width: 500px;
    margin-top: -60px;
}

.ContactForm .contactinfo .infor {
    padding-left: 100px;
    text-align: center;
}

.ContactForm .contactinfo .infor h3 {
    font-family: 'ConcordLight';
    font-weight: 400;
    font-size: 32px;
    margin: 0;
    padding: 0;
    margin-bottom: 24px;
}

.ContactForm .contactinfo .infor h2 {
    font-size: 46px;
    font-family: 'ConcordBold';
    margin: 0;
    padding: 0;
}

.ContactForm .centers {
    display: flex;
    background-color: #f1f1f1;
    width: 100%;
    padding: 120px 100px 100px;
    justify-content: space-between;
}

.ContactForm .centers .box {
    width: 30%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.ContactForm .centers .box img {
    width: 100px;
}

.ContactForm .centers .box span {
    width: 100%;
    font-size: 20px;
    text-align: center;
    margin-top: 20px;
}

@media only screen and (max-width: 1000px) {
    .ContactForm form {
        width: 100%;
        padding: 0 20px;
        color: #404040;
    }

    .ContactForm .centers {
        width: 100%;
        padding: 30px;
        flex-wrap: wrap;
    }

    .ContactForm .centers .box {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 40px;
    }

    .ContactForm .centers .box img {
        width: 100px;
    }

    .ContactForm .centers .box span {
        width: 100%;
        font-size: 20px;
        text-align: center;
        margin-top: 20px;
    }

    .ContactForm .contactinfo .infor h2 {
        font-size: 30px;
    }

    .ContactForm .contactinfo img {
        display: none;
    }

    .ContactForm .contactinfo .infor {
        padding: 30px;
        text-align: center;
        width: 100%;
    }
}