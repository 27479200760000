.App {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.container {
    /* width: 1280px; */
    width: 100% !important;
    max-width:100% !important;
    padding:0 !important;
}

.space {
    width: 100%;
    height: 300px;
    background-color: #ddd;
}

/* @media only screen and (max-width: 1000px) {
    .container {
        width: 390px;
    }
} */