.Welcome {
    margin-top: 40px;
    padding: 0 8%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.headerr {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
}

.headerr .partners {
    padding-top: 20px;
    display: flex;
    align-items: center;
    width: calc(100% - 440px);
    justify-content: space-between;
}

.headerr .partners img {
    height: 25px;
}

.headerr .partners a {
    border: none;
    background-color: #43907a;
    padding: 4px 12px;
    color: white;
    border-radius: 4px;
    text-decoration: none;
    margin-left: 20px;
}

.Welcome .container {
    width: 70%;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 50px;
}

.Welcome .container h1 {
    font-family: 'ConcordBold';
    font-size: 55px;
    color: #424343;
    margin: 0;
    padding: 0;
}

.Welcome .container h4 {
    font-family: 'ConcordLight';
    font-size: 32px;
    color: #424343;
    line-height: 55px;
    margin: 0;
    padding: 0;
    padding: 30px 0;
    font-weight: 400;
}

.Welcome .container .logos {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

@media only screen and (max-width: 1200px) {
    .headerr .title {
        display: none;
    }
    .headerr .partners {
        width: 100%;
        padding: 10px 0;
    }
    .headerr .partners img {
        width: 25%;
        max-width: 150px;
        height: auto;
    }

    .Welcome .container {
        width: 100%;
        padding: 0 30px;
    }

    .Welcome .container h1 {
        font-size: 36px;
    }
    .Welcome .container br {
        display: none;
    }
    .Welcome .container h4 {
        font-size: 24px;
        line-height: 42px;
        padding: 20px 0;
    }
    .Welcome .logos {
        width: 100%;
    }
    .Welcome .logos img {
        width: 30%;
        height: auto;
    }
}