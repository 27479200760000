.Choose {
    color: #424343;
}

.Choose .img-choose {
    margin: 70px auto;
    width: calc(100% - 20%);
    height: 0;
    padding-bottom:15%;
    background: url(../../../../imgs/numberswhyus.png);
    background-size: 100% auto;
    background-position: center;
    background-repeat: no-repeat;
}

@media only screen and (min-width: 1000px) {
    .Choose .img-choose-mob {
        display: none;
    }
    .Choose .img-choose-mob2 {
        display: none;
    }
}

@media only screen and (max-width: 1000px) {

    .Choose .image {
        width: 100%;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin: 70px 0;
    }

    .Choose .img-choose {
        display: none;
    }

    .Choose .img-choose-mob {
        width: 360px;
        height: 111px;
        background: url(../../../../imgs/numberswhyusmob1.png);
        background-size: contain;
        margin-bottom: 15px;
    }

    .Choose .img-choose-mob2 {
        width: 360px;
        height: 111px;
        background: url(../../../../imgs/numberswhyusmob2.png);
        background-size: contain;
    }
}